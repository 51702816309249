// URL
export enum CTA {
  Contact = "Contact Us",
  GetStarted = "Get Started",
  GetInTouch = "Get In Touch",
  SignIn = "Sign In",
  SignUp = "Sign Up",
  Subscribe = "Subscribe Now",
  Demo = "Request a Demo",
  Access = "Request Early Access",
  Book = "Book an Appointment",
  Target = "Target",
  Walmart = "Walmart",
  JoinPartner = "Join Our Partner Program",
  Learn = "Learn More",
  Theme = "Request a Theme"
}
