import classNames from "classnames";
import React, { useState } from "react";
import Link from "../../Link";
import Pill from "../../Pill";
import { NavigationBarDropdownItem as NavigationBarDropdownRowProps } from "../models";

const NavigationBarDropdownRow: React.FC<NavigationBarDropdownRowProps> = props => {
  const { to, title, icon, disabled, badgeText, onClick } = props;
  const [active, setActive] = React.useState<boolean>(false);
  return (
    <div
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
    >
      <Link
        onClick={onClick}
        to={to}
        className={classNames(
          "flex items-center group p-3 space-x-3 text-base font-semibold rounded-lg transition-all",
          {
            "hover:bg-gray-6-light": !disabled
          }
        )}
      >
        <div
          className={classNames(
            "rounded-full p-2 bg-gray-5-light transition-all",
            {
              "group-hover:bg-white group-hover:shadow": !disabled
            }
          )}
        >
          <div className={classNames({ "grayscale-hover": !active })}>
            {icon}
          </div>
        </div>
        <p>{title}</p>
        {badgeText && <Pill text={badgeText} />}
      </Link>
    </div>
  );
};

export default NavigationBarDropdownRow;
