import classNames from "classnames";
import React from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import UIPopover, { UIPopoverSide } from "../../UIPopover";
import { NavigationBarDropdownItem, NavigationBarVariant } from "../models";
import NavigationBarDropdownRow from "../NavigationBarDropdownRow";

interface DropperProps {
  rows: NavigationBarDropdownItem[];
  navigationBarVariant?: NavigationBarVariant;
}

const NavigationBarDropdown: React.FC<
  React.PropsWithChildren<DropperProps>
> = props => {
  const { rows, children, navigationBarVariant } = props;

  const triggerRef = React.useRef(null);
  const dropperRef = React.useRef(null);

  const [showDropper, setShowDropper] = React.useState<boolean>(false);

  useOnClickOutside([triggerRef, dropperRef], () => setShowDropper(false));

  const dropper = (
    <div
      ref={dropperRef}
      className="hidden lg:block rounded-lg shadow bg-white border border-gray-4-light p-3 min-w-60 transition-all"
    >
      <ul>
        {rows.map((row, idx) => {
          return <NavigationBarDropdownRow key={idx} {...row} />;
        })}
      </ul>
    </div>
  );
  return (
    <UIPopover open={showDropper} side={UIPopoverSide.bottom} content={dropper}>
      <div
        className={classNames("cursor-pointer", {
          "text-gray-3-dark hover:text-gray-1":
            navigationBarVariant === NavigationBarVariant.default &&
            !showDropper,
          "text-white hover:text-opacity-60":
            navigationBarVariant === NavigationBarVariant.light && !showDropper,
          "text-gray-1":
            showDropper &&
            navigationBarVariant === NavigationBarVariant.default,
          "text-white text-opacity-60":
            showDropper && navigationBarVariant === NavigationBarVariant.light
        })}
        ref={triggerRef}
        onClick={() => setShowDropper(!showDropper)}
      >
        {children}
      </div>
    </UIPopover>
  );
};

export default NavigationBarDropdown;
