import classNames from "classnames";
import React from "react";

export enum ButtonVariant {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  primaryWhite = "primary-white",
  secondaryWhite = "secondary-white"
}

export enum ButtonSize {
  default = "default",
  large = "large"
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The buttons variant
   */
  variant: ButtonVariant;
  /**
   * The size of the button
   */
  size?: ButtonSize;
  /**
   * The text to appear in the middle of the button
   */
  text?: string;
}

const Button: React.FC<ButtonProps> = props => {
  const {
    variant,
    size = ButtonSize.default,
    text,
    onClick,
    className,
    ...rest
  } = props;

  return (
    <button
      {...rest}
      className={classNames(
        "relative flex flex-row items-center justify-center border rounded-lg whitespace-nowrap transition-colors",
        {
          "px-4 py-2":
            size === ButtonSize.default && variant !== ButtonVariant.tertiary,
          "px-6 py-3":
            size === ButtonSize.large && variant !== ButtonVariant.tertiary,
          "text-lg font-semibold": size === ButtonSize.large,
          "text-base font-semibold": size === ButtonSize.default,
          [[
            "border-transparent",
            "text-white",
            "bg-indigo-1",
            "hover:bg-indigo-2",
            "active:bg-indigo-3-dark",
            "disabled:bg-gray-4-light",
            "disabled:text-gray-2-light",
            "disabled:border-gray-4-light"
          ].join(" ")]: variant === ButtonVariant.primary,
          [[
            "bg-white",
            "text-gray-3-dark",
            "border-gray-3-light",
            "hover:border-gray-2-light",
            "active:bg-gray-6-light",
            "active:border-gray-1",
            "disabled:border-gray-4-light",
            "disabled:text-gray-3-light"
          ].join(" ")]: variant === ButtonVariant.secondary,
          [[
            "p-0",
            "bg-transparent",
            "border-transparent",
            "text-indigo-1",
            "hover:text-indigo-2",
            "active:text-indigo-3-dark",
            "disabled:text-gray-3-light"
          ].join(" ")]: variant === ButtonVariant.tertiary,
          [[
            "bg-white",
            "text-indigo-1",
            "border-transparent",
            "hover:bg-indigo-2-light",
            "active:bg-purple-active-button"
          ].join(" ")]: variant === ButtonVariant.primaryWhite,
          [[
            "bg-transparent",
            "text-white",
            "border-white",
            "hover:text-indigo-2-light",
            "hover:border-indigo-2-light",
            "active:text-purple-active-button",
            "active:border-purple-active-button"
          ].join(" ")]: variant === ButtonVariant.secondaryWhite
        },
        className
      )}
      onClick={onClick}
      style={{ outline: "none" }}
    >
      {text}
    </button>
  );
};

export default React.memo(Button);
