import classNames from "classnames";
import React, { useState } from "react";
import { animated, useSpring, UseSpringProps } from "react-spring";
import UIPortal from "../UIPortal";

export enum UIPopoverSide {
  top = "top",
  left = "left",
  right = "right",
  bottom = "bottom"
}

interface UIPopoverProps {
  /* The desired side of the popover  */
  side?: UIPopoverSide;
  /* The content that should be in the popover */
  content?: React.ReactNode;
  /* open the popover should show */
  open?: boolean;
  /* whether to align to centre (false) or top (true) vertically for left/right popovers */
  alignTop?: boolean;
}

const UIPopover: React.FC<React.PropsWithChildren<UIPopoverProps>> = props => {
  const { side, open, content, children, alignTop } = props;

  const defaultPadding = 8;

  const triggerRef = React.useRef<HTMLDivElement>();
  const popoverRef = React.useRef<HTMLDivElement>();

  /** Position of the animated popover */
  const [popoverPosition, setPopoverPosition] = React.useState<
    UseSpringProps<React.CSSProperties>
  >({
    from: {
      opacity: 0
    }
  });

  // Additional state that controls whether popover is rendered
  const [hidden, setHidden] = React.useState<boolean>(true);
  const [width, setWidth] = React.useState<number>(0);

  const commonSpringOptions = {
    reverse: !open,
    config: {
      duration: 200
    }
  };
  const animatedProps = useSpring({
    ...commonSpringOptions,
    ...popoverPosition
  });

  // saving height here when the popover is closed and we set the height to zero
  const [savedHeight, setSavedHeight] = useState<number>(0);
  // checking a property of the trigger's boundaries such that if the trigger itself is initially hidden, the popover will update when it is shown for the first time
  const triggerBoundsTop =
    triggerRef === undefined
      ? 0
      : triggerRef?.current?.getBoundingClientRect().top;

  /**
   * Used to determine the positioning of the popover based on the desired side
   * updates the popoverPosition which triggers an animation
   * TODO: change side depending on window bounds
   */
  const updatePopoverPosition = React.useCallback(() => {
    const triggerRect = triggerRef?.current?.getBoundingClientRect();
    const tooltipRect = popoverRef?.current?.getBoundingClientRect();

    if (!triggerRect || !tooltipRect) {
      return;
    }

    const actualHeight =
      tooltipRect !== undefined && tooltipRect.height !== 0
        ? tooltipRect.height
        : savedHeight;

    const midPointX = triggerRect.width / 2 - tooltipRect.width / 2;
    const midPointY = triggerRect.height / 2 - actualHeight / 2;

    switch (side) {
      case UIPopoverSide.top:
        setPopoverPosition({
          top: triggerRect?.top - actualHeight - defaultPadding,
          left:
            triggerRect?.left + midPointX + tooltipRect.width + 48 < width
              ? triggerRect?.left + midPointX
              : width - 48 - tooltipRect.width,
          opacity: 1,
          from: {
            top: triggerRect?.top - actualHeight,
            left:
              triggerRect?.left + midPointX + tooltipRect.width + 48 < width
                ? triggerRect?.left + midPointX
                : width - 48 - tooltipRect.width,
            opacity: 0
          }
        } as any);
        break;
      case UIPopoverSide.right:
        setPopoverPosition({
          top: triggerRect?.top + (alignTop ? 0 - defaultPadding : midPointY),
          left: triggerRect?.right + defaultPadding,
          opacity: 1,
          from: {
            top: triggerRect?.top + (alignTop ? 0 - defaultPadding : midPointY),
            left: triggerRect?.right,
            opacity: 0
          }
        } as any);
        break;
      case UIPopoverSide.bottom:
        setPopoverPosition({
          top: triggerRect?.bottom + defaultPadding,
          left:
            triggerRect?.left + midPointX + tooltipRect.width + defaultPadding <
            width
              ? triggerRect?.left + midPointX
              : width - 48 - tooltipRect.width,
          opacity: 1,
          from: {
            top: triggerRect?.bottom,
            left:
              triggerRect?.left + midPointX + tooltipRect.width + 48 < width
                ? triggerRect?.left + midPointX
                : width - 48 - tooltipRect.width,
            opacity: 0
          }
        } as any);
        break;
      case UIPopoverSide.left:
        setPopoverPosition({
          top: triggerRect?.top + (alignTop ? 0 - defaultPadding : midPointY),
          left: triggerRect?.left - tooltipRect?.width - defaultPadding,
          opacity: 1,
          from: {
            top: triggerRect?.top + (alignTop ? 0 - defaultPadding : midPointY),
            left: triggerRect?.left - tooltipRect?.width,
            opacity: 0
          }
        } as any);
        break;
    }
  }, [side, savedHeight, width, alignTop]);

  const updateWidth = React.useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  React.useEffect(() => {
    if (!width) {
      setWidth(window.innerWidth);
    }
  }, [width]);

  React.useEffect(() => {
    if (open) {
      setHidden(false); // Set the hidden flag
      updatePopoverPosition();
    } else {
      const tempHeight = popoverRef?.current?.getBoundingClientRect().height; // save the current height
      setSavedHeight(tempHeight);
      updatePopoverPosition();
      setTimeout(() => {
        // set the hidden flag, but only after a delay
        setHidden(true);
      }, 100);
    }
  }, [open, updatePopoverPosition]);

  // when the trigger bounds change, we update the popover position
  React.useEffect(() => {
    updatePopoverPosition();
  }, [triggerBoundsTop, updatePopoverPosition]);

  React.useEffect(() => {
    window.addEventListener("resize", updatePopoverPosition);
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updatePopoverPosition);
      window.removeEventListener("resize", updateWidth);
    };
  }, [updatePopoverPosition]);

  return (
    <>
      <div className="flex" ref={triggerRef}>
        {children}
      </div>
      <UIPortal>
        <animated.div
          className={classNames(
            "fixed z-50",
            hidden ? "h-0 overflow-hidden" : ""
          )}
          style={animatedProps}
          ref={popoverRef}
        >
          {content}
        </animated.div>
      </UIPortal>
    </>
  );
};

export default UIPopover;
