import classNames from "classnames";
import React from "react";
interface SectionProps {
  className?: string;
}

const Section: React.FC<React.PropsWithChildren<SectionProps>> = props => {
  const { children, className } = props;
  return (
    <div className={classNames("max-w-8xl mx-auto px-4 md:px-12", className)}>
      {children}
    </div>
  );
};

export default React.memo(Section);
