import React from "react";
import Section from "../../containers/Section";
import Icon, { IconType } from "../Icon";
import Link from "../Link";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Footer: React.FC<{}> = () => {
  const {t} = useTranslation();
  const copyrights = `${new Date().getFullYear()} Perpetua. All rights reserved.`;
  return (
    <div className="relative bg-white">
      <Section>
        <div className="text-base font-semibold text-gray-2-light my-20 md:my-24 lg:my-32 flex flex-wrap">
          <div className="w-1/2 md:w-1/4 space-y-4">
            <p className="text-gray-3-dark">{t("Resources")}</p>
            <ul className="space-y-4">
              {/* <li>
                <Link
                  to="/blog"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("Blog")}
                </Link>
              </li> */}
              <li>
                <Link
                  to="https://adschool.perpetua.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("Ad School")}
                </Link>
              </li>
              <li>
                <Link
                  to="http://help.perpetua.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("Help Center")}
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UCVPWE5c8kRN6QOcp8ntIbTw"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("Videos")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-1/2 md:w-1/4 space-y-4 mb-10 md:mb-0">
            <p className="text-gray-3-dark">{t("Company")} 🌿</p>
            <ul className="space-y-4">
              <li>
                <Link
                  to="/about-us"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("About Us")}
                </Link>
              </li>
              <li>
                <Link
                  to="https://jobs.lever.co/perpetualabs.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("Careers")}
                </Link>
              </li>
              {/* <li>
                <a
                  href="/privacy"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("Privacy Policy")}
                </a>
              </li>
              <li>
                <a
                  href="/tos"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("Terms of Service")}
                </a>
              </li> */}
            </ul>
          </div>
          <div className="w-1/2 md:w-1/4 space-y-4">
            <p className="text-gray-3-dark">{t("Download")} 📱</p>
            <ul className="space-y-4">
              <li>
                <Link
                  to="https://apps.apple.com/us/app/perpetua-labs/id1331586234"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("iOS")}
                </Link>
              </li>
              <li>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.perpetua.android"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  {t("Android")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-1/2 md:w-1/4 space-y-4">
            <p className="text-gray-3-dark">{t("Contact")} 💌</p>
            <ul className="space-y-4">
              <li>
                <a
                  href="mailto:hello@perpetua.io"
                  className="hover:text-gray-1 active:text-gray-2-dark"
                >
                  hello@perpetua.io
                </a>
              </li>
              <li className="font-medium block">
                <Link
                  to="https://goo.gl/maps/5m9PhwdbdpfKDK2Z9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("footerAddress")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-5-light text-sm text-gray-2-light font-medium text-center py-8 md:flex md:justify-between md:items-center">
          <p>&copy; {t(copyrights)}</p>
          <div className="flex justify-center items-center space-x-6 mt-5 md:mt-0">
            <a href="https://www.linkedin.com/company/perpetua-labs/">
              <Icon iconType={IconType.linkedin} />
            </a>
            <a href=" https://www.instagram.com/perpetua.io/">
              <Icon iconType={IconType.instagram} />
            </a>
            <a href=" https://www.youtube.com/channel/UCVPWE5c8kRN6QOcp8ntIbTw">
              <Icon iconType={IconType.youtube} />
            </a>
            <a href=" https://twitter.com/PerpetuaLabs">
              <Icon iconType={IconType.twitter} />
            </a>
            <a href="https://www.facebook.com/perpetualabs/">
              <Icon iconType={IconType.facebook} />
            </a>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default React.memo(Footer);
