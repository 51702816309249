/**
 * Feb 16th 2020. Ported from from https://github.com/perpetua1/perpetua-blog/blob/f0794e000cb13cbbf121e43f2ba6710e6ff408b6/src/modules/navigation.js
 */
import { navigate } from "gatsby";

/**
 * Gives utm parameters a custom key so metrics don't get wacky.
 * ex. utm_campaign => p_utm_campaign
 * @param key
 */
function toPerpetuaKey(key) {
  return `p_${key}`;
}

/**
 * Attaches the current query parameters to the url provided, converting them to perpetua keys
 * Only attaches the utm parameters if it is a link internal to gatsby or a perpetua.io domain
 * @param url The URL to append the UTM parameter to, string
 */
export function withUTM(url) {
  if (!url) return;
  const internal = isInternalToGatsby(url) || isInternalToPerpetua(url);
  if (!internal) return encodeURI(url);
  if (typeof document === `undefined`) return encodeURI(url);
  const currentURL = new URL(document.location);

  // Loop through possbile utm keys - appending to the new URL we're navigating to
  const utmKeys = ["utm_source", "utm_campaign", "utm_medium"];
  utmKeys.forEach(key => {
    let pKey = toPerpetuaKey(key);
    // If we have the utm parameter in the current set of query params, copy value & convert to p_${utm param}
    if (currentURL.searchParams.has(key)) {
      const value = currentURL.searchParams.get(key);
      url = addQueryParam(url, pKey, value);
    } else if (currentURL.searchParams.has(pKey)) {
      // Same thing, but for the perpetua key
      const value = currentURL.searchParams.get(pKey);
      url = addQueryParam(url, pKey, value);
    }
  });
  return encodeURI(url);
}

/**
 * Appends a query parameter to a given string
 * @param originalPath the path, absolute or relative, including or excluding any query parameters
 * @param key the key of the query parameter to be added or updated
 * @param value the value of the query parameter to be added or updated
 */
export function addQueryParam(originalPath, key, value) {
  const indexOfParams = originalPath.indexOf("?");
  const paramsString =
    indexOfParams > 0 ? originalPath.slice(indexOfParams) : "";
  const params = new URLSearchParams(paramsString);
  const path =
    indexOfParams > 0 ? originalPath.slice(0, indexOfParams) : originalPath;

  // Otherwise, add the query param
  params.set(key, value);
  return path + "?" + params.toString();
}

/**
 * Navigates to a url
 * Appends UTM parameters available in the current URL but converts to p_utm_X style,
 * only when navigating internally in Gatsby OR to perpetua.io
 * If no URL provided, just return
 * @param url the URL to navigate to
 */
export function nav(url) {
  if (!url) return;

  if (isInternalToGatsby(url)) {
    navigate(withUTM(url));
  } else {
    window.location.href = withUTM(url);
  }
}

/**
 * Perform a quick check to see if the page we're navigating to is internal (a gatsby page) or external
 */
export function isInternalToGatsby(url) {
  return (
    /^\/(?!\/)/.test(url) && !url.includes("https") && !url.includes("http")
  );
}

/**
 * Quick check to see if the url includes "perpetua.io"
 */
export function isInternalToPerpetua(url) {
  return url.includes("perpetua.io");
}
