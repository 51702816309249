import classNames from "classnames";
import React from "react";
import useMedia from "use-media";
import Section from "../../containers/Section";
import { nav } from "../../utils/navigation";
import Button, { ButtonVariant } from "../Button";
import Icon, { IconType } from "../Icon";
import Link from "../Link";
import Amazon from "./assets/amazon.svg";
import LogoWordmarkWhite from "./assets/logo-wordmark-white.svg";
import LogoWordmark from "./assets/logo-wordmark.svg";
import DSP from "./assets/sponsored-display.svg";
import Video from "./assets/video.svg";
import DesktopNavigationBarItem from "./DesktopNavigationBarItem";
import MobileNavigationBarItem from "./MobileNavigationBarItem";
import { NavigationBarItem, NavigationBarVariant } from "./models";
import { URL } from "../../constants/destinations/index";
import { CTA } from "../../constants/text/index";
import { Name, Type, Location } from "../../utils/analytics/constants";
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface NavigationBarProps {
  variant?: NavigationBarVariant;
  primaryButtonId?: string;
  secondaryButtonId?: string;
}

const NavigationBar: React.FC<NavigationBarProps> = props => {
  const { variant, primaryButtonId, secondaryButtonId } = props;

  const {t} = useTranslation();

  const isDesktop = useMedia({ minWidth: "1024px" });

  const [isSticky, setIsSticky] = React.useState<boolean>();
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
  const [
    activeVariant,
    setActiveVariant
  ] = React.useState<NavigationBarVariant>(variant);

  React.useEffect(() => {
    if (isSticky || showMobileNav) {
      setActiveVariant(NavigationBarVariant.default);
    } else {
      setActiveVariant(variant);
    }
  }, [isSticky, showMobileNav]);

  React.useEffect(() => {
    /**
     * When the app is in desktop mode force the mobile
     * navigation to close
     */
    if (isDesktop) {
      setShowMobileNav(false);
    }
  }, [isDesktop]);

  React.useEffect(() => {
    window?.addEventListener("scroll", handleScroll);
    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const shouldStick = window?.scrollY > 10;
    if (isSticky !== shouldStick) {
      setIsSticky(shouldStick);
    }
  };

  const navigationBarItems: NavigationBarItem[] = [
    {
      title: t("Marketplaces"),
      dropdownRows: [
        {
          to: "/amazon-advertising-software",
          title: t("Amazon"),
          icon: <Amazon />
        },
      ]
    },
    {
      title: t("Features"),
      dropdownRows: [
        {
          to: "/amazon-dsp",
          title: t("Display Advertising"),
          icon: <DSP />
        },
        {
          to: "/sponsored-brands-video",
          title: t("Video Advertising"),
          icon: <Video />
        }
      ]
    },
    {
      to: "/agencies",
      title: t("Agencies")
    },
    {
      to: "/blog",
      title: t("Blog")
    },
    {
      to: "/pricing",
      title: t("Pricing")
    }
  ];

  const logo = React.useMemo(() => {
    switch (activeVariant) {
      case NavigationBarVariant.light:
        return <LogoWordmarkWhite />;
      default:
        return <LogoWordmark />;
    }
  }, [activeVariant]);

  /** Desktop Navigation */
  const desktopNav = (
    <nav>
      <ul
        className={classNames(
          "flex flex-col space-x-10 font-semibold lg:flex-row",
          {
            "text-white": activeVariant === NavigationBarVariant.light
          }
        )}
      >
        {navigationBarItems.map((item, idx) => (
          <DesktopNavigationBarItem
            key={idx}
            navigationBarVariant={activeVariant}
            {...item}
          />
        ))}
      </ul>
    </nav>
  );

  /** Mobile Navigation */
  const mobileNav = (
    <nav className="py-6">
      <ul className="text-3xl font-semibold space-y-10">
        {navigationBarItems.map((item, idx) => (
          <MobileNavigationBarItem key={idx} {...item} />
        ))}
      </ul>
    </nav>
  );

  return (
    <>
      {showMobileNav && (
        <div className="fixed top-0 h-screen w-screen z-10 bg-gray-6-dark bg-opacity-40 lg:hidden" />
      )}
      <div
        className={classNames(
          "fixed top-0 w-full bg-none z-30 transition ease-in-out duration-1000",
          {
            "transition-colors": !showMobileNav,
            "bg-white": showMobileNav || isSticky,
            shadow: isSticky || showMobileNav,
            "overflow-hidden  rounded-bl-xl rounded-br-xl": showMobileNav
          }
        )}
      >
        <Section>
          <div className="py-3">
            <div className="flex justify-between items-center md:space-x-10">
              <Link to={"/"}>{logo}</Link>
              {/* Menu Toggle: Mobile & Tablet */}
              <div
                className="lg:hidden"
                onClick={() => setShowMobileNav(!showMobileNav)}
              >
                <Icon
                  className={classNames("fill-current", {
                    "text-gray-3-dark":
                      activeVariant === NavigationBarVariant.default,
                    "text-white": activeVariant === NavigationBarVariant.light
                  })}
                  iconType={showMobileNav ? IconType.close : IconType.menu}
                />
              </div>
              {/* Desktop Navigation */}
              <div className="hidden lg:block">{desktopNav}</div>
              <div className="hidden lg:flex space-x-3">
                <Button
                  id={secondaryButtonId}
                  variant={
                    activeVariant === NavigationBarVariant.light
                      ? ButtonVariant.secondaryWhite
                      : ButtonVariant.secondary
                  }
                  text={t("Sign In")}
                  onClick={() => {
                    nav(URL.Login);
                  }}
                />
                <Button
                  id={primaryButtonId}
                  variant={
                    activeVariant === NavigationBarVariant.light
                      ? ButtonVariant.primaryWhite
                      : ButtonVariant.primary
                  }
                  text={t("Get Started")}
                  onClick={() => {
                    nav(URL.SignUp);
                  }}
                />
              </div>
            </div>
          </div>
        </Section>
        {/* Mobile Navigation  */}
        {showMobileNav && (
          <div className="bg-white mt-6 lg:hidden">
            <Section>
              <div className="flex items-center space-x-6 mb-5">
                <Button
                  id={secondaryButtonId}
                  className="w-full"
                  variant={ButtonVariant.secondary}
                  text={CTA.SignIn}
                  onClick={() => {
                    nav(URL.Login);
                  }}
                />
                <Button
                  id={primaryButtonId}
                  className="w-full"
                  variant={ButtonVariant.primary}
                  text={CTA.GetStarted}
                  onClick={() => {
                    nav(URL.SignUp);
                  }}
                />
              </div>
              {mobileNav}
            </Section>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(NavigationBar);