import classNames from "classnames";
import React from "react";
import Link from "../../Link";
import Chevron from "../assets/chevron.svg";
import { NavigationBarItem as MobileNavigationBarItemProps } from "../models";
import NavigationBarDropdownRow from "../NavigationBarDropdownRow";

const MobileNavigationBarItem: React.FC<MobileNavigationBarItemProps> = props => {
  const { to, title, dropdownRows } = props;

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <li>
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Link to={to}>{title}</Link>
        {dropdownRows && (
          <Chevron
            className={classNames("transform", { "-rotate-90": !isOpen })}
          />
        )}
      </div>
      {dropdownRows && isOpen && (
        <div className="mt-5">
          {dropdownRows.map((row, idx) => (
            <NavigationBarDropdownRow key={idx} {...row} />
          ))}
        </div>
      )}
    </li>
  );
};

export default MobileNavigationBarItem;
