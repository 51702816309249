import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import Chevron from "../assets/chevron.svg";
import { NavigationBarItem, NavigationBarVariant } from "../models";
import NavigationBarDropdown from "../NavigationBarDropdown";

interface DesktopNavigationBarItemProps extends NavigationBarItem {
  navigationBarVariant: NavigationBarVariant;
}

const DesktopNavigationBarItem: React.FC<DesktopNavigationBarItemProps> = props => {
  const { to, title, dropdownRows, navigationBarVariant } = props;
  if (dropdownRows) {
    return (
      <li>
        <NavigationBarDropdown
          rows={dropdownRows}
          navigationBarVariant={navigationBarVariant}
        >
          <div className={"flex items-center flex-nowrap whitespace-nowrap"}>
            <Link to={to}>{title}</Link>
            <Chevron className="ml-1 fill-current" />
          </div>
        </NavigationBarDropdown>
      </li>
    );
  }
  return (
    <li
      className={classNames(
        "flex items-center flex-nowrap whitespace-nowrap transition-colors",
        {
          "text-gray-3-dark hover:text-gray-1":
            navigationBarVariant === NavigationBarVariant.default,
          "text-white hover:text-opacity-60":
            navigationBarVariant === NavigationBarVariant.light
        }
      )}
    >
      <Link to={to}>{title}</Link>
    </li>
  );
};

export default DesktopNavigationBarItem;
