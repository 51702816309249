import React from "react";
import ReactDOM from "react-dom";

const UIPortal: React.FC<React.PropsWithChildren<{}>> = props => {
  const { children } = props;

  const [container, setContainer] = React.useState<Element>(null);

  React.useEffect(() => {
    if (!container) {
      setContainer(document.createElement("div"));
    }
  }, []);

  React.useEffect(() => {
    if (container && !document.body.contains(container)) {
      document.body.appendChild(container);
    }
    return () => {
      if (container) {
        document?.body?.removeChild(container);
      }
    };
  }, [container]);

  if (container) {
    return ReactDOM.createPortal(children, container);
  }
  return null;
};

export default React.memo(UIPortal);
