import React from "react";
// brands
import Cartograph from "./brands/cartograph.svg";
// cta
import Play from "./cta/play.svg";
import ArrowRight from "./icons/arrowRight.svg";
// Display Advertising
import Book from "./icons/book.svg";
// page icons
import Checkbox from "./icons/checkbox.svg";
// pricing icons
import Checkmark from "./icons/checkmark.svg";
import Close from "./icons/close.svg";
import Dollar from "./icons/dollar.svg";
import DspBlack from "./icons/dsp-black.svg";
import Dsp from "./icons/dsp.svg";
import Ellipse from "./icons/ellipse.svg";
import Eye from "./icons/eye.svg";
import Global from "./icons/global.svg";
import InfoDark from "./icons/info-dark.svg";
import Info from "./icons/info.svg";
import Instacart from "./icons/instacart.svg";
import Walmart from "./icons/walmart.svg";
import Lightbulb from "./icons/lightbulb.svg";
import Menu from "./icons/menu.svg";
import OptimizeBid from "./icons/optimize-bid.svg";
import Optimize from "./icons/optimize.svg";
import Plus from "./icons/plus.svg";
import Replay from "./icons/replay.svg";
import Rocket from "./icons/rocket.svg";
import Sales from "./icons/sales.svg";
import Search from "./icons/search.svg";
import Sort from "./icons/sort.svg";
import Store from "./icons/store.svg";
import Time from "./icons/time.svg";
import Union from "./icons/union.svg";
import Video from "./icons/video.svg";
// social icons
import Facebook from "./social/facebook.svg";
import Instagram from "./social/instagram.svg";
import Linkedin from "./social/linkedin.svg";
import Twitter from "./social/twitter.svg";
import Youtube from "./social/youtube.svg";
import FacebookColored from "./social/facebookColored.svg";
import LinkedinColored from "./social/linkedinColored.svg";
import TwitterColored from "./social/twitterColored.svg";

// icons
import Clock from "./icons/shapeclock.svg";

export enum IconType {
  // brands
  cartograph = "Cartograph",

  // cta
  play = "Play",

  // page icons
  checkbox = "Checkbox",
  close = "Close",
  dsp = "Dsp",
  lightbulb = "Lightbulb",
  menu = "Menu",
  optimize = "Optimize",
  rocket = "Rocket",
  sales = "Sales",
  video = "Video",
  union = "Union",
  instacart = "Instacart",
  walmart = "Walmart",
  ellipse = "Ellipse",
  arrowRight = "ArrowRight",
  optimizeBid = "OptimizeBid",
  sort = "Sort",
  time = "Time",

  // social icons
  facebook = "Facebook",
  twitter = "Twitter",
  linkedin = "Linkedin",
  youtube = "Youtube",
  instagram = "Instagram",
  facebookColored = "FacebookColored",
  twitterColored = "TwitterColored",
  linkedinColored = "LinkedinColored",

  // Display Advertising
  dspBlack = "DspBlack",
  book = "Book",
  dollar = "Dollar",
  global = "Global",
  replay = "Replay",
  eye = "Eye",
  search = "Search",
  store = "Store",

  // pricing icons
  checkmark = "Checkmark",
  plus = "Plus",
  info = "Info",
  infoDark = "InfoDark",

  clock = "Clock"
}

const iconComponentMap = new Map<string, typeof React.Component>([
  [IconType.cartograph, Cartograph],

  [IconType.play, Play],

  [IconType.checkbox, Checkbox],
  [IconType.close, Close],
  [IconType.dsp, Dsp],
  [IconType.lightbulb, Lightbulb],
  [IconType.menu, Menu],
  [IconType.optimize, Optimize],
  [IconType.rocket, Rocket],
  [IconType.sales, Sales],
  [IconType.video, Video],
  [IconType.dsp, Dsp],
  [IconType.union, Union],
  [IconType.instacart, Instacart],
  [IconType.walmart, Walmart],
  [IconType.ellipse, Ellipse],
  [IconType.arrowRight, ArrowRight],
  [IconType.optimizeBid, OptimizeBid],
  [IconType.sort, Sort],
  [IconType.time, Time],

  [IconType.facebook, Facebook],
  [IconType.twitter, Twitter],
  [IconType.linkedin, Linkedin],
  [IconType.youtube, Youtube],
  [IconType.instagram, Instagram],
  [IconType.facebookColored, FacebookColored],
  [IconType.twitterColored, TwitterColored],
  [IconType.linkedinColored, LinkedinColored],

  [IconType.dspBlack, DspBlack],
  [IconType.book, Book],
  [IconType.dollar, Dollar],
  [IconType.global, Global],
  [IconType.replay, Replay],
  [IconType.eye, Eye],
  [IconType.search, Search],
  [IconType.store, Store],

  [IconType.checkmark, Checkmark],
  [IconType.plus, Plus],
  [IconType.info, Info],
  [IconType.infoDark, InfoDark],

  [IconType.clock, Clock]
]);

interface IconProps {
  iconType: IconType;
  className?: string;
}

// Component for svg icons

const Icon: React.FC<IconProps> = props => {
  const { iconType, className } = props;
  const I = iconComponentMap.get(iconType);
  return <I className={className} />;
};

export default React.memo(Icon);
