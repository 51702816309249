import React from "react";
import CookieConsent from "react-cookie-consent";
import Footer from "../../components/Footer";
import NavigationBar from "../../components/NavigationBar";
import { NavigationBarVariant } from "../../components/NavigationBar/models";
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';

interface LayoutProps {
  navigationBarVariant?: NavigationBarVariant;
  primaryButtonId?: string;
  secondaryButtonId?: string;
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = props => {
  const {
    children,
    navigationBarVariant = NavigationBarVariant.default,
    primaryButtonId,
    secondaryButtonId
  } = props;

  const {t} = useTranslation();

  return (
    <div className="overscroll-x-hidden md:overscroll-x-auto relative">
      <CookieConsent
        disableStyles={true}
        buttonText={t("Accept")}
        containerClasses="cookie-consent-container"
        contentClasses="cookie-consent-content"
        buttonClasses="cookie-consent-button"
      >
        {t("We use cookies and other tracking technologies to improve our website and your web experience.")}
        <Link to="/privacy">{t("Privacy Policy")}</Link>.
      </CookieConsent>
      <NavigationBar
        variant={navigationBarVariant}
        primaryButtonId={primaryButtonId}
        secondaryButtonId={secondaryButtonId}
      />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;