import React from "react";

interface PillProps {
  text: string;
}

const Pill: React.FC<PillProps> = props => {
  const { text } = props;
  return (
    <div className="flex bg-blue-3-light rounded-xl items-center px-2 ml-3 whitespace-nowrap">
      <p className="text-sm font-semibold bg-clip-text text-transparent bg-gradient-to-r from-indigo-1 to-blue-1">
        {text}
      </p>
    </div>
  );
};

export default Pill;
