// URL
export enum URL {
    Login = "https://app.perpetua.io/login",
    SignUp = "https://app.perpetua.io/signup",
    Agency = "/agencies",
    Video = "https://app.perpetua.io/brands/add/video/?openvideocreator=true"
}

export enum Intercom {
    Open = "intercom"
}