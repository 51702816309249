export enum NavigationBarVariant {
  default = "default",
  light = "light"
}

export interface NavigationBarDropdownItem {
  to?: string;
  title: string;
  icon?: React.ReactElement;
  badgeText?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export interface NavigationBarItem {
  to?: string;
  title: string;
  dropdownRows?: NavigationBarDropdownItem[];
}
